import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

const Error = () => {
  const i18n = useIntl().formatMessage

  return (
    <div className="row">
        { i18n({ id: "pages.404.title" })}
    </div>
    )
}

export default Error
